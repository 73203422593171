import Q from 'q';
import DialogBox from '@/page/admin/office/my-apply/components/dialogBox.vue'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/traveler-multiple-selector/2.2.5/index.vue';
import consumer_approveFlow_getApproveNodes from '@/lib/data-service/haolv-default/consumer_approveFlow_getApproveNodes'
import consumer_apply_aduitEvection from "@/lib/data-service/haolv-default/consumer_apply_aduitEvection";
import consumer_apply_aduitOverproofOnBusiness from "@/lib/data-service/haolv-default/consumer_apply_aduitOverproofOnBusiness";

export default {
    data() {
        return {
            initParams: {
                approveType: '', // approveType 审批类型：1.出差申请，2.超标申请，3.报销申请，4.改签，5.退票
                applyNo: '', // 审批单号
                approveFlowId: '', // 审批流程流水号
                isHasFlow: false, // 是否有流程
                isLastNode: false, // 是否流程最后一个
            },

            dialogShow: false,
            staffList: [],

            dialogChangeShow: false,
            staffRadio: 1,

            staffIdList: [],

            applyResolve: '',
            applyReject: '',

        }
    },
    components: {DialogBox, TravelerMultipleSelector},
    created() {
    },
    mounted() {
        this.$refs.travelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log('args', args)
            let selected_entity_list = args.selected_entity_list
            selected_entity_list.forEach(value => {
                value.id = value.userId
            })
            this._getApplyList(selected_entity_list)
        };
        this.$refs.travelerMultipleSelector.init_parameter.get_data_source_parameter = function () {
            const d = {
                companyId: '',
                isSelectCustomer: false,
            };
            return Q.resolve(d);
        };
        this.$refs.travelerMultipleSelector.init();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _getApplyList(val) {
            let data = {
                applyNo: this.initParams.applyNo,
                approveStatus: 2,
                isOver: val.length ? false : true,
                nextApproverId: val.length ? val[0].id : "",
                nextApproverName: val.length ? val[0].name : "",
                rejectReason: "",
            };
            if (this.initParams.approveType === 2) {
                // 超标
                consumer_apply_aduitOverproofOnBusiness(data).then((res) => {
                    this.$message({ type: "success", message: "审批通过！" });
                    this.applyResolve(true)
                }).catch((err) => {
                    this.applyReject(false)
                });
            }
            if (this.initParams.approveType === 1) {
                // 出差单申请
                consumer_apply_aduitEvection(data).then((res) => {
                    this.$message({ type: "success", message: "审批通过！" });
                    this.applyResolve(true)
                }).catch((err) => {
                    this.applyReject(false)
                });
            }

        },
        _setShow() {
            this.dialogChangeShow = false
            if (this.staffRadio === 1) {
                // 不需要下一个
                this._getApplyList([])
            } else {
                // 需要下一个
                this.$refs.travelerMultipleSelector.show();
            }
        },
        _reactParams() {
            this.initParams = {
                approveType: '', // approveType 审批类型：1.出差申请，2报销申请，3.超标申请，4.改签，5.退票
                applyNo: '', // 审批单号
                approveFlowId: '', // 审批流程流水号
                isHasFlow: false, // 是否有流程
                isLastNode: false, // 是否流程最后一个
            }
        },

        run(val) {
            // 检验入参
            if (val.isHasFlow === undefined || val.isHasFlow === null) {
                console.error('没有传入是否有审批流程')
                return
            }
            if (val.isHasFlow === true && (val.isLastNode === undefined || val.isLastNode === null)) {
                console.error('没有传入是否最后节点')
                return;
            }
            if (val.applyNo === undefined || val.applyNo === null) {
                console.error('没有传入审批流程单号')
                return
            }
            if (val.approveType === undefined || val.approveType === null) {
                console.error('没有传入审批类型')
                return
            }
            this._reactParams()
            this.initParams = Object.assign({}, this.initParams, val)

            return new Promise((resolve, reject) => {
                this.applyResolve = resolve
                this.applyReject = reject

                // 是否有审批流程
                if (this.initParams.isHasFlow) {
                    // 是否最后节点
                    if (this.initParams.isLastNode) {
                        // 是
                        this._getApplyList([])
                    } else {
                        // 否
                        let params = {
                            applyNo: this.initParams.applyNo,
                            approveFlowId: this.initParams.approveFlowId
                        };
                        consumer_approveFlow_getApproveNodes(params).then(res => {
                            let staffList = res.datas.staffs;
                            staffList.forEach(value => {
                                value.id = value.userId;
                                value.name = value.staffName;
                            });
                            this.staffList = staffList;
                            // 判断审批流是否有人
                            if (this.staffList.length === 1) {
                                // 只有一个人
                                this._getApplyList(this.staffList)
                            } else if (this.staffList.length > 1) {
                                // 有多个人
                                this.dialogShow = true
                            } else {
                                // 没人
                                this.$refs.travelerMultipleSelector.show();
                            }
                        }, error => {
                            reject()
                        }).catch(err => {
                            reject()
                        })
                    }
                } else {
                    // 没有审批流
                    this.dialogChangeShow = true
                }
            })

        },
    }
}
