//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DialogBox",
  data() {
    return {
      approvalUser: ''
    };
  },
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    staffList: {
      type: Array,
      default: () => []
    },
  },
  watch: {},
  methods: {
    updateShow(val) {
      this.$emit("update:dialogShow", val);
    },
    setShow() {
      this.$emit("getApplyList", [this.approvalUser]);
      this.updateShow(false);
    }
  },
  created() {},
  activated() {},
  watch: {},
  computed: {
  },
  components: { }
};
